* {
  -webkit-tap-highlight-color: #0000;
}

body {
  height: 100vh;
  margin: 0;
}

#root {
  background-color: #e5f8fc;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  display: flex;
}

@media (width >= 968px) {
  #root {
    height: auto;
    min-height: 100%;
  }
}

#ie-warning {
  margin: 20px;
  font-family: Titillium Web;
  display: none;
}
/*# sourceMappingURL=index.51c5aec8.css.map */
