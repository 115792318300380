* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  background-color: #e5f8fc;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (min-width: 968px) {
  #root {
    height: auto;
    min-height: 100%;
  }
}

#ie-warning {
  margin: 20px;
  font-family: 'Titillium Web';
  display: none;
}
